import { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './Layout';

const Homepage = lazy(() => import('./pages/Homepage'));



const App = () => (
  <BrowserRouter>
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route exact path="/" element={<Homepage />} />
        <Route exact path="*" element={<div>404</div>} />
      </Routes>
    </Suspense>
  </BrowserRouter>
);

export default App;
